import avatar1 from '../images/avatars/1.png';
import avatar2 from '../images/avatars/2.png';
import avatar3 from '../images/avatars/3.png';
import avatar4 from '../images/avatars/4.png';
import avatar5 from '../images/avatars/5.png';
import avatar6 from '../images/avatars/6.png';
import avatar7 from '../images/avatars/7.png';
import avatar8 from '../images/avatars/8.png';
import avatar9 from '../images/avatars/9.png';
import avatar10 from '../images/avatars/10.png';
import avatar111 from '../images/avatars/11.png';
import me from '../images/me.jpg'
import dev from '../images/dev.jpg'
import italy from '../images/italy.jpg'
import italy2 from '../images/italy2.jpg'

import bg1 from '../images/shapes/19.jpg';
import bg2 from '../images/shapes/24.jpg'
import bg3 from '../images/shapes/26.jpg'
import bg4 from '../images/shapes/44.jpg'
import bg5 from '../images/shapes/213.png'
import bg6 from '../images/shapes/17.jpg'
import bg7 from '../images/shapes/65.jpg'
import bg8 from '../images/shapes/44.jpg'




const images = {
avatar1,
avatar2,
avatar3,
avatar4,
avatar5,
avatar6,
avatar7,
avatar8,
avatar9,
avatar10,
avatar111,
me,
dev,
italy,
italy2,
bg1,
bg2,
bg3,
bg4,
bg5,
bg6,
bg7,
bg8
}

export default images;